import Button from "../../components/button/button";
import EmpresaService, { EmpresaDeleteInput, EmpresaOutput } from "../../services/EmpresaService";
import Voltar from "../../components/voltar/voltar";
import Spinner from "../../components/spinner/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Table } from "../../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Modal from "../../components/modal/modal";
import EmpresaEdicao from "./empresa-edicao/empresa-edicao";
import Feedback from "../../components/feedback/feedback";
import Action from "../../components/action/action";
import ErroDeNegocio from "../../errors/ErroDeNegocio";
import useAppFeedback from "../../hooks/use-app-feedback";

const styles = {
  table: {
    columns: { 'Codigo': { width: '150px' }, 'Ações': { width: '80px' } }
  }
}

const Empresa = () => {
  const navigate = useNavigate();

  const headers = [{ property: 'Codigo', name: 'Código' }, 'Nome', 'Ações'];
  const [empresas, setEmpresas] = useState<EmpresaOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [estaEditando, setEstaEditando] = useState(false);
  const [empresaParaAtualizar, setEmpresaParaAtualizar] = useState<EmpresaOutput>();
  const { feedbacks, adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks, removerFeedback } = useAppFeedback();
  const location = useLocation();

  const cadastrarNovaEmpresa = useCallback(() => {
    limparFeedbacks();
    return navigate('/empresa/novo');
  }, [limparFeedbacks, navigate]);

  const obterListaDeEmpresas = useCallback(async () => {
    setLoading(true);
    const listaDeEmpresas = await EmpresaService.obterEmpresas();
    const empresas = listaDeEmpresas.content.empresas;

    if (empresas.length === 0) {
      setEmpresas(value => value.length ? [] : value);
      setLoading(false);
      return;
    }

    setEmpresas(empresas);
    setLoading(false);
    if (location.state?.result) {
      adicionarFeedbackDeSucesso(location.state?.result);
      navigate(location.pathname, { replace: true });
    }
  }, [adicionarFeedbackDeSucesso, location, navigate]);

  const editarEmpresa = useCallback(async (empresa: EmpresaOutput): Promise<boolean> => {
    limparFeedbacks();
    setEstaEditando(true);
    setEmpresaParaAtualizar(empresa);
    return false;
  }, [limparFeedbacks])

  const removerEmpresa = useCallback((async (empresa: EmpresaOutput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true);
    try {
      const empresaDelete: EmpresaDeleteInput = {
        EmpresaId: empresa.id
      }
      await EmpresaService.removerEmpresa(empresaDelete);
      adicionarFeedbackDeSucesso('Empresa removida com Sucesso!');
      obterListaDeEmpresas();
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
    }
    setLoading(false);
    if (location.state?.result) {
      adicionarFeedbackDeSucesso(location.state?.result);
      navigate(location.pathname, { replace: true });
    }
    return true;
  }), [adicionarFeedbackDeErro, adicionarFeedbackDeSucesso, limparFeedbacks, obterListaDeEmpresas, location, navigate]);

  const cancelarAtualizar = useCallback(() => {
    limparFeedbacks();
    setEstaEditando(false);
    setEmpresas([]);
  }, [limparFeedbacks]);

  useEffect(() => {
    if (!empresas.length)
      obterListaDeEmpresas();

  }, [obterListaDeEmpresas, empresas])

  useEffect(() => {
    return () => limparFeedbacks();
  }, [limparFeedbacks]);

  return (
    <>
      <h1 className="top-title"><Voltar />Lista de Empresas</h1>
      <div className='content-container'>
        {!estaEditando && <Feedback feedbacks={feedbacks} remover={removerFeedback} />}
        {loading && <div className='loading'>
          <Spinner size={170} />
        </div>}
        <Button classes="submit-button" style={{ margin: '5px' }}
          text={<><FontAwesomeIcon icon={faPlus} /> Nova</>}
          onClick={() => cadastrarNovaEmpresa()}></Button>
        <Table.Root>
          <Table.Header headers={headers} columnStyles={styles.table.columns}></Table.Header>
          <Table.Body
            headers={headers}
            data={empresas}
            actions={(value) => <>
              <Action title="Editar" icon={faPenToSquare} value={value} onRowClick={editarEmpresa} />
              <Action title="Remover" icon={faTrash} value={value} onRowClick={removerEmpresa}
                confirm
                confirmMessage={<>
                  Tem certeza que deseja excluir a empresa ?<br />
                  <strong>{(value as EmpresaOutput).codigo} - {(value as EmpresaOutput).nome}</strong><br />
                </>} />
            </>}
          />
        </Table.Root>
      </div>
      {<Modal isOpened={estaEditando}>
        <EmpresaEdicao empresaParaAtualizar={empresaParaAtualizar as EmpresaOutput} clickCancelar={cancelarAtualizar} />
      </Modal>}
    </>
  );
}

export default Empresa;